import React, { useState, useEffect, useCallback } from "react"
import { graphql, Link, navigate } from "gatsby"
import { Helmet } from "react-helmet"
import SEO from "../../components/seo"
import Layout from "../../modules/layout"
import Breadcrumbs from "../../modules/breadcrumbs"
import SimpleHero from "../../modules/simpleHero"
import Grid from "../../modules/grid"
import Pagination from "../../components/pagination"
import styles from "./resourceList.module.scss"
import Select from "../../components/select"
import client from "../../utils/sanityClient"
import Loader from "../../components/loader"
import generatePagesObject from "../../helpers/generatePagesObject"

const limit = 12
const defaultCategoryOptions = [{ value: "all", label: "All categories" }]
const justPublished = ` && !(_id match "*drafts*")`
const defaultContentTypeOptions = [
  { value: "all", label: "All content types" },
  { value: "case-studies", label: "Case studies" },
]

const ResourcePage = props => {
  const { data, location } = props
  const title = `Resources`
  const description = null
  const image = null
  const canonical = `${data.sanitySiteConfig.url}/resources/`

  const [resources, setResources] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [categoryOptions, setCategoryOptions] = useState([])
  const [contentTypeOptions, setContentTypeOptions] = useState([])

  const params = new URLSearchParams(location.search)
  const contentType = params.get("content_type")
  const category = params.get("product")
  const showCaseStudyCategory = contentType === "case-studies"

  const categorySelectValue = categoryOptions.find(
    option => option.value === category
  )
  const contentTypeSelectValue = contentTypeOptions.find(
    option => option.value === contentType
  )
  const contentTypeLabel =
    contentType && contentType !== "all"
      ? contentTypeOptions.find(option => option.value === contentType)?.label
      : "resources"

  const updateCurrentPage = useCallback(page => {
    setCurrentPage(page)
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }, [])

  const onChangeCategory = useCallback(
    option => {
      navigate(
        `/resources/?product=${option.value}&content_type=${contentType ||
          "all"}`
      )
      if (currentPage !== 1) updateCurrentPage(1)
    },
    [updateCurrentPage, currentPage, contentType]
  )

  const onChangeContentType = useCallback(
    option => {
      navigate(
        `/resources/?product=${category || "all"}&content_type=${option.value}`
      )
      if (currentPage !== 1) updateCurrentPage(1)
    },
    [updateCurrentPage, currentPage, category]
  )

  const getResources = useCallback(
    async (category, contentType, currentPage) => {
      setIsLoading(true)
      const categoryFilter =
        category && category !== "all"
          ? ` && category->slug.current == "${category}"`
          : ""

      const contentTypeFilter =
        contentType && contentType !== "all"
          ? ` && contentType->slug.current == "${contentType}"`
          : ""

      let items = { resources: [], caseStudies: [] }

      const caseStudies =
        !contentType ||
        (contentType && contentType === "all") ||
        (contentType && contentType === "case-studies")
          ? `count(*[_type == "caseStudy"${justPublished}${categoryFilter}])`
          : "0"

      try {
        const totalCounts = await client.fetch(`
          {
            "resourcesCount": count(*[_type == "resource"${justPublished}${categoryFilter}${contentTypeFilter}]),
            "caseStudiesCount": ${caseStudies}
          }
        `)

        const pages = generatePagesObject(
          totalCounts.resourcesCount,
          totalCounts.caseStudiesCount,
          limit
        )

        const { limit: resourceLimit = 0, skip: resourceSkip = 0 } =
          pages[currentPage]?.resource || {}
        const { limit: caseStudyLimit = 0, skip: caseStudySkip = 0 } =
          pages[currentPage]?.caseStudy || {}

        items = await client.fetch(`
          {
            "resources": *[_type == "resource"${justPublished}${categoryFilter}${contentTypeFilter}]
            | order(publishedAt desc) [${resourceSkip}...${resourceSkip +
          resourceLimit}] {
              "node": {
                ...,
                "id": _id,
                slug {
                  current
                },
                category->{
                  title,
                  slug {
                    current
                  }
                },
                contentType->{
                  title,
                  slug {
                    current
                  }
                },
                image {
                  asset->{
                    ...,
                  },
                },
              }
            },
            "caseStudies": *[_type == "caseStudy"${justPublished}${categoryFilter}]
            | order(publishedAt desc) [${caseStudySkip}...${caseStudySkip +
          caseStudyLimit}] {
              "node": {
                ...,
                "id": _id,
                slug {
                  current
                },
                category->{
                  title,
                  slug {
                    current
                  }
                },
              }
            },
          }
        `)

        setTotalPages(
          Math.ceil(
            (totalCounts.resourcesCount + totalCounts.caseStudiesCount) / limit
          )
        )
        setResources([...items.resources, ...items.caseStudies])
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        console.error("Error fetching options:", error)
      }
    },
    []
  )

  const getOptions = useCallback(async () => {
    try {
      const options = await client.fetch(
        `{
          "categoryOptions": *[_type == "resource"${justPublished} && defined(category)]
            | { "label": category->title, "value": category->slug.current },
          "contentTypeOptions": *[_type == "resource"${justPublished} && defined(contentType)]
            | { "label": contentType->title, "value": contentType->slug.current },
        }`
      )

      const categoryOptions = Array.from(
        new Set(
          options.categoryOptions.map(
            option => `${option.label}:${option.value}`
          )
        )
      )
        .map(combined => {
          const [label, value] = combined.split(":")
          return { label, value }
        })
        .concat(defaultCategoryOptions)
        .sort((a, b) => a.label.localeCompare(b.label))

      const contentTypeOptions = Array.from(
        new Set(
          options.contentTypeOptions.map(
            option => `${option.label}:${option.value}`
          )
        )
      )
        .map(combined => {
          const [label, value] = combined.split(":")
          return { label, value }
        })
        .concat(defaultContentTypeOptions)
        .sort((a, b) => a.label.localeCompare(b.label))

      setCategoryOptions(categoryOptions)
      setContentTypeOptions(contentTypeOptions)
    } catch (error) {
      console.error("Error fetching options:", error)
    }
  }, [])

  useEffect(() => {
    getOptions()
  }, [getOptions])

  useEffect(() => {
    getResources(category, contentType, currentPage)
  }, [category, contentType, currentPage])

  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href={canonical} />
      </Helmet>
      <SEO title={title} description={description} image={image} />
      <Breadcrumbs location={location}>
        <Link to="/">Home</Link>
        <p>Resources</p>
      </Breadcrumbs>
      <SimpleHero
        heading={`PeopleFluent ${contentTypeLabel}`}
        className={styles.headeTitle}
      >
        <div className={styles.selects}>
          <Select
            onChange={onChangeCategory}
            value={categorySelectValue}
            options={categoryOptions}
            placeholder="Filter by product"
          />
          <Select
            onChange={onChangeContentType}
            value={contentTypeSelectValue}
            options={contentTypeOptions}
            placeholder="Filter by content type"
          />
        </div>
      </SimpleHero>
      <section className="blocksGrid">
        {isLoading && (
          <div
            className={`${styles.loader} ${
              !!resources.length ? styles.withOverlay : ""
            }`}
          >
            <Loader withOverlay={!!resources.length} />
          </div>
        )}

        {!!resources.length ? (
          <Grid
            blocks={resources}
            moreCols
            showCaseStudyCategory={showCaseStudyCategory}
          />
        ) : !isLoading ? (
          <div className={styles.noContentText}>
            Sorry, no resources match these filters.
          </div>
        ) : null}

        {totalPages > 1 && (
          <Pagination
            type="resources"
            currentPage={currentPage}
            totalPages={totalPages}
            updateCurrentPage={updateCurrentPage}
          />
        )}
      </section>
    </Layout>
  )
}

export default ResourcePage

export const query = graphql`
  query {
    sanitySiteConfig {
      url
    }
  }
`
