import React from "react"
import ReactSelect from "react-select"
import styles from "./select.module.scss"

const customComponents = {
  IndicatorSeparator: null,
}

const reactSelectStyles = {
  control: baseStyles => ({
    ...baseStyles,
    borderRadius: "20px",
    boxShadow: "none",
    border: "none",

    "&:hover": {
      boxShadow: "none",
      border: "none",
    },
  }),
  menu: baseStyles => ({
    ...baseStyles,
    borderRadius: "20px",
  }),
  menuList: baseStyles => ({
    ...baseStyles,
    borderRadius: "20px",
    padding: "5px",

    "& > :not(:last-child)": {
      marginBottom: "5px",
    },
  }),
  singleValue: baseStyles => ({
    ...baseStyles,
    color: "#623491",
    fontWeight: 700,
  }),
  placeholder: baseStyles => ({
    ...baseStyles,
    fontSize: "14px",
    fontWeight: 700,
    color: "black",
  }),
  dropdownIndicator: baseStyles => ({
    ...baseStyles,
    color: "#623491",

    "&:hover": {
      cursor: "pointer",
      color: "#623491",
    },
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    borderRadius: "20px",
    color: state.isSelected ? "white" : "#623491",
    backgroundColor: state.isSelected
      ? "#623491"
      : state.isFocused
      ? `rgba(133, 112, 178, 0.18)`
      : "white",
    cursor: "pointer",
    textAlign: "start",
  }),
}

const Select = ({ onChange, options, placeholder, value }) => {
  return (
    <ReactSelect
      className={styles.select}
      components={customComponents}
      onChange={onChange}
      options={options}
      value={value}
      placeholder={placeholder}
      isSearchable={false}
      styles={reactSelectStyles}
    />
  )
}

export default Select
