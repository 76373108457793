function generatePagesObject(entity1Count, entity2Count, limit) {
  const pages = {}
  let currentPage = 1
  let totalResourceLimit = 0
  let totalCaseStudyLimit = 0

  while (entity1Count > 0 || entity2Count > 0) {
    const entity1PerPage = Math.min(limit, entity1Count)
    const entity2PerPage = Math.min(limit - entity1PerPage, entity2Count)

    if (entity1PerPage > 0) {
      pages[currentPage] = {
        resource: { limit: entity1PerPage, skip: totalResourceLimit },
      }
      totalResourceLimit += entity1PerPage
      entity1Count -= entity1PerPage
    }

    if (entity2PerPage > 0) {
      pages[currentPage] = {
        ...pages[currentPage],
        caseStudy: { limit: entity2PerPage, skip: totalCaseStudyLimit },
      }
      totalCaseStudyLimit += entity2PerPage
      entity2Count -= entity2PerPage
    }

    currentPage++
  }

  return pages
}

export default generatePagesObject
